/**
 * 全局注册的一些组件
*/

import Vue from 'vue'
import selectTable from './select-table'
import FormModal from './form-modal'

Vue.component('selectTable', selectTable)
Vue.component('FormModal', FormModal)
