export default {
  user: {
    state: {
      userInfo: {}
    },
    getters: {
      getUserInfo(state) {
        state.userInfo = JSON.parse(sessionStorage.getItem('--ADMIN_WEB--USER_INFO'))
        return state.userInfo
      }
    },
    mutations: {},
    actions: {}
  },
  system: {
    state: {
      systemInfo: []
    },
    getters: {
      getSystem(state) {
        state.userInfo = JSON.parse(sessionStorage.getItem('--ADMIN_WEB--ALL_SYSTEM'))
        return state.userInfo
      }
    }
  }
}
