import moment from 'moment'
const install = function (Vue, options) {
  Vue.prototype.dateFormat = function dateFormat(tick) {
    return moment(tick * 1).format('YYYY-MM-DD HH:mm:ss')
  }
  Vue.prototype.dayFormat = function dayFormat(tick) { // 年月日
    return moment(tick * 1).format('YYYYMMDD')
  }
  Vue.prototype.timeFormat = function timeFormat(tick) { // 时分秒
    return moment(tick).format('HH:mm:ss')
  }
  Vue.prototype.MDFormat = function dayFormat(tick) { // 月日
    return moment(tick * 1).format('MM月DD日')
  }
  Vue.prototype.mul = function mul(a, b) { // 乘法
    var c = 0
    var d = a.toString()
    var e = b.toString()
    try {
      c += d.split('.')[1].length
    } catch (f) {}
    try {
      c += e.split('.')[1].length
    } catch (f) {}
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
  }
  Vue.prototype.div = function div(a, b) { // 除法
    var c
    var d
    var e = 0
    var f = 0
    try {
      e = a
        .toString()
        .split('.')[1]
        .length
    } catch (g) {}
    try {
      f = b
        .toString()
        .split('.')[1]
        .length
    } catch (g) {}
    c = Number(a.toString().replace('.', ''))
    d = Number(b.toString().replace('.', ''))
    return (c / d) * (Math.pow(10, f - e))
  }
  Vue.prototype.add = function add(arg1, arg2) { // 加法
    var r1, r2, m, c
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    c = Math.abs(r1 - r2)
    m = Math.pow(10, Math.max(r1, r2))
    if (c > 0) {
      var cm = Math.pow(10, c)
      if (r1 > r2) {
        arg1 = Number(arg1.toString().replace('.', ''))
        arg2 = Number(arg2.toString().replace('.', '')) * cm
      } else {
        arg1 = Number(arg1.toString().replace('.', '')) * cm
        arg2 = Number(arg2.toString().replace('.', ''))
      }
    } else {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', ''))
    }
    return (arg1 + arg2) / m
  }
  Vue.prototype.sub = function sub(arg1, arg2) { // 减法
    var r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    // last modify by deeka
    // 动态控制精度长度
    n = (r1 >= r2) ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
  }
  Vue.prototype.round = function round(v, e) {
    var t = 1
    for (; e > 0; t *= 10, e--)
    ;
    for (; e < 0; t /= 10, e++)
    ;
    return Math.round(v * t) / t
  }
  Vue.prototype.handleClipboard = function handleClipboard(text, event) { // 复制
    let Clipboard = require('clipboard')
    const clipboard = new Clipboard(event.target, {
      text: () => text
    })
    clipboard.on('success', e => {
      Vue.prototype.$Message.success(`${text}复制成功！`)
      clipboard.off('error')
      clipboard.off('success')
      clipboard.destroy()
    })
    clipboard.on('error', e => {
      Vue.prototype.$Message.success(`${text}复制失败！`)
      clipboard.off('error')
      clipboard.off('success')
      clipboard.destroy()
    })
    clipboard.onClick(event)
  }

  Vue.prototype.handleFloat = function (val) {
    if (val !== null && !isNaN(val)) {
      let num = Vue.prototype.$BigDecimal.divide(Vue.prototype.$BigDecimal.floor(Vue.prototype.$BigDecimal.multiply(`${val}`, `100000000`)), '100000000', 8)
      return num
    } else {
      return 0
    }
  }

  Vue.prototype.handleDecimalAdd = function (arr) {
    let sum = '0'
    for (let i = 0, len = arr.length; i < len; i++) {
      sum = Vue.prototype.$BigDecimal.add(arr[i], sum)
    }
    return sum
  }

  Vue.prototype.handleString = function (val) {
    if (val && val !== null && val !== undefined) {
      return val
    } else {
      return '--'
    }
  }

  Vue.prototype.handleNotation = function (val) {
    if (parseFloat(val) === 0) {
      return 0
    } else {
      return val
    }
  }

  Vue.prototype.exportExcel = function exportExcel(table, fileName) {
    let tableHtml
    if (table.$refs) {
      let header = table.$refs.header.querySelector('table').innerHTML
      let body = table.$refs.body.querySelector('tbody').outerHTML
      tableHtml = header + body
    } else {
      tableHtml = table.innerHTML
    }
    let uri = 'data:application/vnd.ms-excel;base64,'
    let template = `<html>
            <head><meta charset="UTF-8"></head>
            <body><table border="1">${tableHtml}</table></body>
        </html>`

    var link = document.createElement('a')
    link.id = 'exportExcel'
    link.href = uri + window.btoa(unescape(encodeURIComponent(template)))
    link.download = fileName
    link.click()
  }
}

export default install
