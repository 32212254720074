import HttpClient from '@spark/http-client'
import Vue from 'vue'

class ApiClient extends HttpClient {
  constructor() {
    super(Vue.prototype.$axios)
    // cas的所有应用 applications
    this.applications = Vue.prototype.$casClient.applications
    // ----------------------------------------------------
    this.getCoin = this.getCoin.bind(this)
    this.getToDo = this.getToDo.bind(this)
    this.getIP = this.getIP.bind(this)
    this.getFinance = this.getFinance.bind(this)

    // =======================
  }

  // 获取平台币种
  getCoin() {
    return this.get(`/coin/support`)
  }

  // 获取待办事项
  getToDo() {
    return this.post(`/homepage/wait/deal`)
  }

  // 获取IP
  getIP() {
    return this.post(`/homepage/ip/info`)
  }

  // 获取财务数据
  getFinance(coin) {
    return this.post(`/homepage/financial/data/query?coinName=${coin}`)
  }

  static install(Vue, options) {
    let _this = new ApiClient(options)
    HttpClient.install(Vue, '$ApiClient', _this)
  }
}

export default ApiClient
