<template>
  <Modal v-model="formM.modal" :closable="false" :mask-closable="false">
    <p slot="header" class="title">{{ formM.modalTitle }}</p>
    <Form
      ref="formM.form"
      :model="formM.form"
      :label-width="formM.labelWidth"
      :inline="formM.inline"
    >
      <FormItem
        v-for="(item,index) in formM.formList"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :rules="item.rules"
      >
        <span v-if="item.controlType ==='span'">{{ formM.form[item.prop] }}</span>
        <InputNumber
          v-if="item.controlType ==='number'"
          :min="0"
          v-model="formM.form[item.prop]"
          :placeholder="item.placeholder"
          :class="item.additional === '%' ? 'width90' : 'width100'"
        ></InputNumber>
        <span v-if="item.additional === formM.additional">{{ formM.additional }}</span>
        <!-- 用于InputNumber后面加单位或单独使用 -->
        <Select
          transfer
          v-if="item.controlType ==='select'"
          v-model="formM.form[item.prop]"
          :placeholder="item.placeholder"
        >
          <Option v-for="obj in formM.customList" :value="obj.id" :key="obj.id">{{ obj.name }}</Option>
        </Select>
        <Select
          transfer
          multiple
          v-if="item.controlType ==='selectMul'"
          v-model="formM.form[item.prop]"
          :placeholder="item.placeholder"
        >
          <Option v-for="obj in formM.customList" :value="obj" :key="obj.index">{{ obj }}</Option>
        </Select>
        <Input
          v-if="item.controlType ==='def'"
          v-model.trim="formM.form[item.prop]"
          :placeholder="item.placeholder"
          :disabled="item.disabled"
        ></Input>
        <Input
          v-if="item.controlType ==='textarea'"
          v-model.trim="formM.form[item.prop]"
          type="textarea"
          :rows="4"
          :placeholder="item.placeholder"
        ></Input>
        <CheckboxGroup v-if="item.controlType ==='checkbox'" v-model="formM.form[item.prop]">
          <Checkbox v-for="obj in formM.boxList" :label="obj.id" :key="obj.id"></Checkbox>
        </CheckboxGroup>
        <RadioGroup v-if="item.controlType ==='radio'" v-model="formM.form[item.prop]">
          <Radio label="1">启用</Radio>
          <Radio label="0">禁用</Radio>
        </RadioGroup>
        <i-switch
          v-if="item.controlType ==='switch'"
          v-model="formM.form[item.prop]"
          true-value="1"
          false-value="0"
        />
      </FormItem>
    </Form>
    <p class="additionalP">{{ formM.additionalP }}</p>
    <div slot="footer" class="foot">
      <Button
        type="primary"
        @click="saveEditPass('formM.form')"
        :loading="$store.state.cfdAdmin.loading"
      >{{ formM.saveEditPassBtnName }}</Button>
      <!-- 保存 -->
      <Button @click="cancelEdit('formM.form')">{{ formM.cancelBtnName }}</Button>
      <!-- 取消 -->
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'formModal',
  props: {
    formM: {
      type: Object,
      default: () => {
        return {
          inline: false,
          modal: false,
          modalTitle: String,
          labelWidth: Number,
          formList: Array,
          customList: Array,
          boxList: Array,
          form: {},
          cancelBtnName: String,
          saveEditPassBtnName: String,
          additional: String,
          additionalP: String
        }
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    saveEditPass (name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.$store.state.cfdAdmin.loading = true
          this.$emit('savePass')
        }
      })
    },
    cancelEdit (name) {
      // this.$refs[name].resetFields()
      this.formM.modal = false
    }
  },
  created () {},
  watch: {}
}
</script>
<style lang="less">
@import './form-modal.less';
</style>
