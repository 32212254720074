<style lang="less">
@import './select-table.less';
</style>

<template>
  <div>
    <div slot="title" class="clearfloat">
      <span style="font-weight: bold; line-height: 24px;">{{ sl.title }}</span>
      <Button style="float: right" size="small" type="primary" @click="handleRefresh">刷新</Button>
    </div>
    <!-- 中间头部布局 -->
    <Row class="top" type="flex" justify="start" v-if="sl.name">
      <!-- 左边一个选框右边一个按钮 -->
      <Col span="12">
        <span>{{sl.name}}</span>
        <Select transfer v-model="sl.val" @on-change="search">
          <Option v-for="item in sl.list" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </Col>
      <Col span="12" class="btn">
        <Button type="primary" @click="show">{{sl.btnName}}</Button>
      </Col>
    </Row>
    <Row class="top" type="flex" justify="start" v-if="!sl.name&&sl.btnName">
      <!-- 左边一个输入框几个选框右边一个按钮 -->
      <Col span="22">
        <Input
          search
          :placeholder="sl.inputPlaceholder"
          v-model.trim="sl.inputVal"
          @on-search="inputSearch"
        />
        <div v-for="i in sl.dataList" :key="i.index" class="list">
          <span>{{i.name}}</span>
          <Select transfer v-model="i.val" @on-change="search">
            <Option v-for="item in i.list" :key="item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </div>
        <label>{{sl.dateName}}</label>
        <DatePicker
          v-show="sl.dateName"
          type="daterange"
          :value="sl.dateValue"
          @on-change="dateChange"
          :options="options"
          :clearable="false"
          split-panels
          placeholder="请选择日期范围搜索"
        ></DatePicker>
      </Col>
      <Col span="2" class="btn">
        <Button
          :loading="$store.state.cfdAdmin.loading"
          type="primary"
          @click="exportExl"
        >{{sl.btnName}}</Button>
      </Col>
    </Row>
    <Row class="top" type="flex" justify="start" v-if="!sl.name&&!sl.btnName">
      <!-- 左边一个输入框时间框右边span -->
      <Col
        :span="16/sl.inputList.length"
        v-show="sl.inputList"
        v-for="item in sl.inputList"
        :key="item.index"
      >
        <!-- 不设置输入提示则不显示输入框 -->
        <Input
          search
          :placeholder="item.inputPlaceholder"
          v-model.trim="item.inputVal"
          @on-search="inputSearch"
        />
        <RadioGroup
          v-model="sl.radio"
          v-for="item in sl.radioList"
          :key="item.index"
          @on-change="radioChange"
        >
          <Radio :label="item.val">{{item.label}}</Radio>
        </RadioGroup>
        <DatePicker
          v-show="sl.radioList"
          type="daterange"
          :value="sl.dateValue"
          @on-change="dateChange"
          :options="options"
          :clearable="false"
          split-panels
          placeholder="请选择日期范围搜索"
        ></DatePicker>
      </Col>
      <Col
        :span="8/sl.spanList.length"
        v-show="sl.inputList"
        class="spanList"
        v-for="item in sl.spanList"
        :key="item.index"
      >
        <span>{{item.name}}</span>
        <span>{{item.val}}</span>
      </Col>
    </Row>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    sl: {
      type: Object,
      default: () => {
        return {
          title: String,
          name: String,
          val: String,
          list: Array,
          btnName: String,
          inputPlaceholder: String,
          inputVal: String,
          dateName: String,
          dateValue: Array,
          dataList: Array,
          inputList: Array,
          spanList: Array,
          radioList: Array,
          radio: String
        }
      }
    }
  },
  data () {
    return {
      options: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now()
        }
      } // 禁选当天及之后的日期
    }
  },
  methods: {
    handleRefresh () {
      this.$emit('refresh')
    },
    show () {
      this.$emit('show')
    },
    inputSearch (val) {
      this.$emit('inputSearch', val)
    },
    search (val) {
      this.$emit('search', val)
    },
    radioChange (val) {
      this.sl.dateValue = val.split(',')
      this.dateChange()
    },
    dateChange (date) {
      this.$emit('dateChange', date)
    },
    exportExl () {
      this.$store.state.cfdAdmin.loading = true
      this.$emit('exportExl')
    }
  }
}
</script>
