export default [{
    path: '/',
    redirect: '/home-page'
  },
  {
    path: '/home-page',
    name: 'home-page',
    children: [{
      path: '/',
      name: 'home-page-list',
      component: resolve => require(['@/views/index.vue'], resolve)
    }]
  }
]
