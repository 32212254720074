import Vue from 'vue'
import iView from 'iview'
import router from './router'
import store from './store'
import base from '@/libs/base'
import ApiClient from './api'
import bigDecimal from 'js-big-decimal'
import './styles/common.less'
import './styles/theme.less'
import '@/components/common/index'
import 'moment/locale/zh-cn'
import moment from 'moment/min/moment-with-locales.min.js'

Vue.use(iView)
Vue.use(base)
Vue.prototype.$BigDecimal = bigDecimal

moment.locale('zh-cn')
Vue.prototype.$moment = moment

const currentSystem = 'H0009'
window.AdminWebBootstrap.Bootstrap({
  VUE_APP_CAS_BASE_PATH: process.env.VUE_APP_CAS_BASE_PATH,
  ApiClient,
  notAuthPermission: true,
  currentSystem,
  systemTheme: 'Mars',
  customClass: 'Navy',
  otherSystems: ['P0009'],
  atSameDomain: process.env.NODE_ENV === 'development',
  router,
  store
}).then(() => {
  window.document.title = window.AdminWebBootstrap.$vue.$store.state.__commonInfo.title
})
